import React from 'react';

import { TextContentBox } from '../common/TextContentBox';

const MobileAppDisabledView = () => {
    return (
        <TextContentBox
            title="Mobile app disabled"
            paragraph1="Your organization does not allow the use of our mobile app."
        />
    );
};

export default MobileAppDisabledView;
